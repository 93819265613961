<template>
  <div id="about-us" class="container pt-5">
    <h1>About iAppraise</h1>

    <div class="grid-100">
      <div class="container row px-0" id="years-together">
        <div class="col-6 yellow-box text-right">
          <img style="height: 100%" src="/assets/img/45-years.png" alt="45-years-image" />
        </div>

        <div class="col-6 text-start headings">
          <span style="display: block; font-weight: 800; font-size: 64px"> Years </span>
          <div>
            <span style="display: block; font-weight: 500; font-size: 24px"> in the automotive space</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container row">
      <div class="col-7 text-start years-together-description">
        <p>
          Our founders share over 45 years in the automotive space and knew the time was right to introduce a technology
          platform that created stronger connections and promoted new ones.
        </p>
      </div>
    </div>

    <div class="container">
      <div class="leading row" id="how-it-began">
        <div class="col-12 col-md-5">
          <h2 class="mb-5">How iAppraise began</h2>
          <p>
            Our founders have been involved in the car industry for 45 years and they felt the time was right to
            introduce a technology solution that reduced bounce backs, increased transparency for both dealer &
            wholesaler, increased efficiency of the appraisal process, provided real time KPI’s into dealerships and
            made this all available in the palm of your hand, welcome to iAppraise.
          </p>
          <span class="highlighted-text"
            >Don’t think of us as a digital appraisal system, this is only part of the picture, we are the only
            appraisal system that saves you money and helps you make more money with more buying opportunities. How do
            we do this?</span
          >
        </div>
        <div class="col-12 col-md-6 illustration">
          <img src="/assets/img/iappraise-history.png" alt="iAppraise-history-image" />
        </div>
      </div>

      <div class="text-grey row justify-content-center">
        <div class="col-12 col-md-5">
          <p>
            We have teamed up with industry experts and a possy of the most skilled technologists in the country to
            design products that are built from scratch - specifically for the automotive industry. To ensure we could
            constantly evolve and improve we made these skilled technologists a bona fide part of our business.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <p>
            Our System allows; dealerships to make more money and save money, promote connections and create new ones,
            increased efficiency of the appraisal process, real time KPI’s into dealerships, dashboard reporting tools
            that allow new measures to be implemented, all available in the palm of your hands. Welcome to iAppraise.
          </p>
        </div>
      </div>
    </div>

    <div class="grid-container text-center" id="aboutus-banner">
      <h2 class="text-center py-5">The universe is complicated, <span> technology should be simple</span></h2>
      <p>We haven't reinvented the wheel — but we have made that wheel spin a lot faster.</p>
    </div>

    <div class="container row">
      <h2 class="text-start mb-5">iAppraise Benefits</h2>
      <div class="benefit">
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-cloud.svg" alt="icon" width="65" height="65" />
            <h3>Secured Cloud Storage</h3>
            <p>All appraisal and trade information will be kept in one central location, forever, in the cloud.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-automate.svg" alt="icon" width="65" height="65" />
            <h3>Automation</h3>
            <p>Automated data population form vin data, helps eliminate user error.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-graph.svg" alt="icon" width="65" height="65" />
            <h3>Dashboard</h3>
            <p>Desktop dashboard reporting made easy for reviewing data.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/notification.svg" alt="icon" width="65" height="65" />
            <h3>Alerts and Calls</h3>
            <p>Seamless alerts and calls to action via push notifications.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-connection.svg" alt="icon" width="65" height="65" />
            <h3>Connect and Promote</h3>
            <p>Not just a digital appraisal system, we facilitate connections and promote new ones when required.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-search.svg" alt="icon" width="65" height="65" />
            <h3>Real Time Tracking</h3>
            <p>Real time trackable data that allows new management KPI’s.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-quickusage.svg" alt="icon" width="65" height="65" />
            <h3>Quick Usage</h3>
            <p>Complete an appraisal in under 5 minutes.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/about-icon-3.gif" alt="icon" width="65" height="65" />
            <h3>Quick Scan</h3>
            <p>Improved turnaround time for clients having their vehicle appraised.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-transparent.svg" alt="icon" width="65" height="65" />
            <h3>Transparent</h3>
            <p>Increased transparency by providing photos for management and wholesalers.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-timesaver.svg" alt="icon" width="65" height="65" />
            <h3>Time Saver</h3>
            <p>Significantly decreases time required on the phone to locate a buyer and negotiate price.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-anywhere.svg" alt="icon" width="65" height="65" />
            <h3>Convenient</h3>
            <p>Vehicles can be appraised, sold and purchased anywhere, anytime.</p>
          </div>
        </div>
        <div class="benefit-item">
          <div class="card-design">
            <img src="/assets/img/icon-reporting.svg" alt="icon" width="65" height="65" />
            <h3>Advanced Reporting</h3>
            <p>Clients can customise reporting functionality.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.6233645270504!2d138.62139742921386!3d-34.9442387987733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cebe3070ffff%3A0x25875c9263a87fb8!2siAppraise!5e0!3m2!1sen!2smy!4v1600327500945!5m2!1sen!2smy"
    height="450"
    frameborder="0"
    style="width: 100%"
    allowfullscreen
  ></iframe>
</template>

<script>
import "bootstrap";

export default {
  name: "AboutUs",
  data: function () {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/buttons.scss";
@import "@/scss/_variables.scss";

#about-us {
  h1,
  h2 {
    font-family: "sf_pro_displaybold";
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;

    @media only screen and (max-width: 600px) {
      font-size: 42px;
      line-height: 45px;
    }
  }

  h3 {
    font-family: "sf_pro_displaybold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 76px;
  }

  p {
    font-family: "sf_pro_displayregular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  }

  span {
    font-family: "sf_pro_displaybold";
  }

  .text-grey {
    color: rgb(104, 104, 104);
  }

  #years-together {
    display: flex;
    margin: 50px auto;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .yellow-box {
      background-color: rgba(255, 230, 8, 1);

      img {
        transform: translate(35px, 60px);

        @media only screen and (max-width: 600px) {
          transform: translate(-35px, 60px);
        }
      }
    }

    .headings {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
      padding: 50px;

      @media only screen and (max-width: 600px) {
        padding: 30px 0;
      }
    }
  }

  .years-together-description {
    padding: 50px 0;

    @media only screen and (max-width: 600px) {
      padding: 10px 0;

      p {
        padding: 0 15px;
      }
    }

    p {
      font-family: "sf_pro_displaysemibold";
      font-size: 24px;
      line-height: 29px;
    }
  }

  #how-it-began {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 80px 0 40px 0;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 30px 0 0 0;
    }

    .illustration {
      padding: 30px;
      margin: auto 0;

      @media only screen and (max-width: 600px) {
        padding: 50px 0;
      }
    }

    .highlighted-text {
      font-family: "sf_pro_displaysemibold";
      font-size: 18px;
      line-height: 21px;
      margin: 1em 0;
    }
  }

  #aboutus-banner {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("/assets/img/cars-banner.png") no-repeat top center;
    background-size: cover;
    padding: 20px 20px 40px 20px;
    margin: 80px auto;

    h2 {
      color: white;
    }

    p {
      color: white;
    }

    span {
      color: rgba(255, 230, 8, 1);
    }
  }

  .benefit {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    margin-bottom: 80px;

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &-item {
      p {
        margin: 0;
      }

      .card-design {
        border: 1px solid #ececec;
        border-left: 9px solid rgba(255, 230, 8, 1);
        box-sizing: border-box;
        height: 100%;
        padding: $GRID_MODULE;
      }
    }
  }
  .ending {
    position: relative;
    background-color: #1b2128;

    .left {
      padding-top: 3em;
      padding-bottom: 3em;
      color: #fff;
      position: relative;

      h2 {
        color: $COLOUR_ACTION;
      }
      .spacer {
        position: absolute;
        top: 0;
        left: -$GRID_MODULE_HALF;
        width: $GRID_MODULE_HALF;
        height: 100%;
        background-color: #1b2128;
      }
    }
    .illustration {
      position: relative;
      background: url(/s/img/about-illustration-3.jpg) no-repeat 50% 50%;
      background-size: cover;
    }
  }
}
</style>
